.wrapper{
    padding-top: 60px;
    padding-left: 69px;
}

.infoService{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;

    .infoServiceTitle{
        font-size: 20px;
    }
}