.loader {
    position: relative;
    width: 80px;
    /* margin: 100px auto; */
    margin-bottom: 22px;
  }
  
  .duo {
    height: 20px;
    width: 50px;
    background: hsla(0, 0%, 0%, 0.0);
    position: absolute;
    
  }
  
  .duo, .dot {
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  .duo1 {
    left: 0;
  }
  
  .duo2 {
    left: 30px
  }
  
  
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #662482;
    position: absolute;
  }
  
  .dotA {
    left: 0px;  
  }
  
  .dotB {
    right: 0px;
  }
  
  
  @keyframes spin {
      0% { transform: rotate(0deg) }
     50% { transform: rotate(180deg) }
    100% { transform: rotate(180deg) }
  }
  
  @keyframes onOff {
      0% { opacity: 0; }
     49% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 1; }
  }
  
  .duo1 {
    animation-name: spin;
  }
  
  .duo2 {
    animation-name: spin;
    animation-direction: reverse;
  }
  
  .duo2 .dotB {
    animation-name: onOff;
  }
  
  .duo1 .dotA {
    opacity: 0;
    animation-name: onOff;
    animation-direction: reverse;
  }