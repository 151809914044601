.root {
    .header {
        display: flex;
        align-items: center;
        width: 100%;
        align-items: center;
        font-weight: bold;
        border: 1px solid #ced4da;
        background-color: #662482;
        color: white;
        box-sizing: border-box;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        margin-top: 31px;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        padding: 1% 2%;

        .rotate{
            transform: rotate(180deg);
        }
        .canSort:hover {
            cursor: pointer;
            color: rgb(233, 233, 233)
            
        }
    }

    .data {
        display: flex;
        flex-direction: column;
    }
}

.row {
    border: 1px solid #ced4da;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 1% 2%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    border-top: none;
    
    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &:hover {
        // cursor: pointer;
        background-color: rgb(246, 246, 246);
    }
    img:hover{
        cursor: pointer
    }
}