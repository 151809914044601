.button {
    /*width: 160px;*/
    /*height: 33px;*/
    width: 187px;
    height: 55px;
    background-color: #662482;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    /* outline-offset: 2px; */
    border-radius: 36px;
    cursor: pointer;

    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    text-transform: uppercase;
    transition: 0.3s ease;
}

.button:hover {
    outline-offset: 0;
    /* outline: 3px solid grey; */
    color: #FFFFFF;
    background-color: #411653;
}


@media screen and (max-width: 800px) {
    .button {
        /*width: 102px;*/
        /*height: 30px;*/
        width: 108px;
        height: 36px;
    }
}