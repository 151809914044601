.tableContainer{
    // padding: 10px;
    padding: 0 25px;
    .listAndTable{
        display: flex;
        flex-direction: row;
        .table{
            width: 50%;
        }
        .list{
            width: 50%;
            margin-top:31px;
            border: 1px solid black;
        }
    }
    .filters{
        display: flex;
        margin-top: 25px;
        gap: 15px;
        .filterBlock{ 
            &__search{
                width: 250px;
                height: 32px;
            }
        }
    }
    
    
}

.forSureDelete{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    justify-content: center;
    .forSureTitle{
        font-size: 22px;
    }
}