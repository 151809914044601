.drawerOpen{
    width: 260px;
    transition: 0.5s ease;
    overflow-y: hidden !important;
    background-color: #DDDB00 !important;
}
.drawerClose{
    width: 70px;
    transition: 0.5s ease;
    overflow-y: hidden !important;
    background-color: #DDDB00 !important;
}
.panelMain{
    box-shadow: 0 0 6px rgb(0 0 0 / 10%);
    height: 100vh;
    background-color: #DDDB00;
    overflow-y: hidden;
    
    .header{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        height: 60px;
        color: #fff;
        .menuIcon{
            cursor: pointer;
        }
        .fashionLogo{
            width: 50px;
        }
    }

    .headerOpen{
        display: flex;
        
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        padding-top: 20px;
        height: 60px;
        color: #fff;
        .menuIcon{
            cursor: pointer;
        }
    }

    .panelItems{
        margin-top: 50px;
        margin-left: 25px;
        padding-right: 20px;
    }
}


.panelItemMain{
    display: flex;
    color: white;
    margin-top: 25px;
    font-size: 18px;
    white-space: nowrap;
    font-weight: 500;
    padding-bottom: 0px;
    cursor: pointer;
    .icon{
        margin-right: 25px;
        padding-bottom: 5px;
    }
}