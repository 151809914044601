.simple_input_wrap {
    position: relative;
    color: #979797;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;

    font-size: 14px;
}

.simple_readonly_wrap {
    position: relative;
    color: #979797;
    background: #FFFFFF;
    border: none;
    width: 100%;
    display:flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
}

.simple_readonly_bold {
    position: relative;
    background: #FFFFFF;
    border: none;
    width: 100%;
    display:flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
}

.simple_input_style {
    border: none;
}

.simple_input_style {
    border: none;
}

.simple_textarea {
    border: none;
    width: 100%;
    resize: none;
    word-break: break-word;
    height: 100%;
}

.simple_textarea:focus {
    outline: none;
}

.simple_input_style:focus {
    outline: none;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.checkbox_wrap {
    height: 20px;
    width: 20px;
    border: 1px solid #E8EBF3;
    border-radius: 2px;
    background: #F8F9FC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.checkbox_checked {
    width: 12px;
    height: 12px;
    background: #BCA263;
    border-radius: 50%;
    cursor: pointer;
}

.checkbox_content {
    margin-left: 18px;
    margin-right: 18px;
}

.buttons {
    cursor: pointer;
}

.counter{
    display: flex;
    flex-direction: column;
    width: 22px;
    height: 28px;
    padding: 3px 0 4px;
    cursor: pointer;
}

.counterItem{
    width: 22px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.counterInput{
    padding-right: 0;
}