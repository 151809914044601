::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    overflow: scroll;
    overflow-x: hidden;
}
.tableContainer{
    // padding: 10px;
    padding: 0 25px;
    .table{
        width: 100%;
    }
    @media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .table{
        width: 130%;
    }
   }

    .filters{
        display: flex;
        margin-top: 25px;
        gap: 15px;
        .filterBlock{ 
            &__search{
                width: 250px;
                height: 32px;
            }
        }
    }
    
    
}

.filterBlock__datePicker{
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
        margin-right: 10px;
    }
}
.sum{
    display: flex;
    align-items: center;
    justify-content: center;
}
.operCount{
    display: flex;
    align-items: center;
    justify-content: center;
}
.deviceStatisticMain{
    min-width: 80vw;
    min-height: 80vh;
    .deviceStatisticHeader{
        color: black;
        font-size: 22px;
    }
    
}
.deviceStatisticTable{
    min-width: 80vw;
    
}

.deviceStatisticMainJobsTableHeader{
    color: black;
    font-size: 22px;
    min-width: 800px;
    
}

.showMore{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.filters{
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
.editVendingModal{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    z-index: 0;
    

    .Naming{
        display: flex;
        flex-direction: row;
        gap:10px;
        border-top: 1px solid rgb(184, 184, 184);
        border-bottom: 1px solid rgb(184, 184, 184);
        padding-bottom: 15px;
        padding-top: 10px;
        width: 100%;
        
        .nameForNaming{
            width: 30%;
            font-weight: bold;
            padding-top: 10px;
        }
        .colomnsForNaming{
            width: 70%;
            padding-top: 10px;
        }
        .NamingColomn{
            padding: 10px 0 10px 0;
        }
    }

    .Adresses{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap:10px;
        // border-top: 1px solid rgb(184, 184, 184);
        border-bottom: 1px solid rgb(184, 184, 184);
        padding-bottom: 15px;
        padding-top: 10px;

        .nameForAdresses{
            width: 30%;
            font-weight: bold;
        }
        .colomnsForAdresses{
            width: 70%;
        }
        .AdressesColomn{
            padding: 10px 0 10px 0;
        }
        .bottomAdresses{
            display: flex;
            flex-direction: row;
            gap:10px;
            .ButtomAdressesColomn{
                width: 50%;
            }
        }

    }

    .other{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap:10px;
        // border-top: 1px solid rgb(184, 184, 184);
        border-bottom: 1px solid rgb(184, 184, 184);
        padding-bottom: 15px;
        padding-top: 10px;

        .NameForOther{
            width: 30%;
            font-weight: bold;
        }
        .pomogite{
            display: flex;
            flex-direction: column;
        .PhoneForOther{
            width: 70%;
            display: flex;
            flex-direction: row;
            gap:10px;
        
        }
        .otherForOther{
            width: 70%;
            display: flex;
            flex-direction: row;
            gap:10px;
            padding-top: 10px;

            .thingForOther{
                // width: 70%;
                bottom: 0;
            }
        }
           
    }
        // 

        //   
        // }
    }
    
    .count{
        display: flex;
        flex-direction: row;
        gap: 50px;
        align-items: center;

        .paper{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .toner{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .time{
        display: flex;
        flex-direction: row;
        gap: 50px;
        align-items: center;

        .advert{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 10px;
        }
        .downtime{
            width: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // padding-right: 10px;
        }
    }
    .phone{
        display: flex;
        flex-direction: column;
        align-items: center;
        // width: 100px;
    }
    .block{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    // .phone{
    //     width: 200px;
    // }


    
}

.deviceCommandsModal{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    .CustomDropDown{
        z-index: 9999999;
    }
}

.deviceAdMain{
    width: 80vw;
    // height: 80vh
}
.deviceAdHeader{
    width: 100%;
    display: flex;
    color: black;
    font-size: 22px; 
}
.deviceFilters{
    margin-top: 20px;
    width: 250px;
}

.rowClassName{
    
}

.badRowClassName{
    background-color: rgba(231, 147, 147, 0.459);
}

.badRowClassName:hover{
    background-color: rgba(231, 147, 147, 0.664);
}
.vendingBadClassName{
    background-color: rgb(180, 180, 180);
}
.vendingBadClassName:hover{
    background-color: rgb(146, 146, 146);
}
.CustomDropDown{
    z-index: 9999999;
}

