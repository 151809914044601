.container {
    position: relative;
    font-size: 14px;
    line-height: 17px;
    min-width: 120px;
    cursor: pointer;
  }
  
  .options_container {
    height: 60px;
    overflow-x: auto;
    position: absolute;
    width: 100%;
    z-index: 99999;
    //top: 40px;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0 25.6px 57.6px rgba(0, 0, 0, 0.132), 0 4.8px 14.4px rgba(0, 0, 0, 0.108);
  }
  .options_container::-webkit-scrollbar-thumb{
    border-radius: 4px;
    background-color: #E1E1E1;
  }
  .options_container::-webkit-scrollbar {
    width: 5px;
  }
  
  .options_container__above{
    height: 150px;
    overflow-x: auto;
    position: absolute;
    width: 100%;
    z-index: 5;
    top: -150px;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0 25.6px 57.6px rgba(0, 0, 0, 0.132), 0 4.8px 14.4px rgba(0, 0, 0, 0.108);
  }
  .select_item {
    //display: flex;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 15px;
    gap: 10px;
  
    padding: 6px 10px;
    cursor: pointer;
    justify-content: space-between;
  }
  
  .select_item_with_img{
    grid-template-columns: 1fr 30px 30px;
  }
  
  .select_item_with_updown {
    justify-content: flex-start;
  }
  
  .select_item__updown{
      margin-left: 8px;
  }
  .select_item__updown_main{
    margin-left: 8px;
    width: 20px;
    position: relative;
  }
  .select_item__updown_img{
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .select_item:last-child {
    margin-bottom: 0;
  }
  
  .select_item:first-child {
    margin-top: 0;
  }
  
  .main_select {
    border: 1px solid #E8EBF3;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 6px 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  
  .selectWithInput{
    display: flex;
  }
  
  .main_select img {
    width: 20px;
    height: 20px;
    object-fit: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
  
  .main_select__dark {
    background: #E7EFFF;
  }
  
  .main_select__light {
    background: #FFFFFF;
  }
  
  .muted_text {
    color: #353535;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }
  
  .blueButton {
    display: flex;
    background: linear-gradient(180deg, #E3F5FF 0%, #C9E7F9 100%);
    border: 1px solid #C9E7F9;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    margin-left: 10px;
  }
  
  .blueButton:hover {
    background: linear-gradient(180deg, #7787a5 0%, #536ea9 100%);
    color: #FFFFFF;
    transition: all .2s ease;
  }
  
  .blueButton:active {
    background: #aec7ff;
    color: #FFFFFF;
    box-shadow: inset 0px 0px 8px 2px rgba(9, 9, 9, 0.9);
  }