p {
    margin: 0;
}

button {
    border: none;
    background: none;
    padding: 0
}

a:hover {
    color: #411653;
}


.roistat-promo{
    font-size: 10px;
    top:0;
    position: fixed;
    left: 1px;
}

.ymaps-map {
    -ms-touch-action: auto !important;
    touch-action: auto !important;
}

.iconLayout{
    background-color: black;
    height: 250px;;
    width: 10px;
}

.ant-picker-header-super-prev-btn {
    display: none;
}

.ant-picker-header-super-next-btn {
    display: none;
}

.ant-picker-header-view {
    position: absolute;
    left: 0;
}

.ant-picker-header {
    padding: 0;
    justify-content: flex-end;
    position: relative;
    border-bottom: none;
    margin-bottom: 18px;
}

.ant-picker-date-panel .ant-picker-body {
    padding: 0;
    
}

.ant-picker-date-panel {
    padding: 23px;
    width: auto;
    
}

.ant-picker-header button {
    line-height: initial;
}

.ant-picker-header-view {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #353535;
}

.ant-picker-content th {
    width: 33px;
    height: 33px;

    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
}

.ant-picker-content td {
    border: 1px solid #E1E1E1;
    width: 33px;
    height: 33px;

    font-size: 10px;
    line-height: 12px;
}

.ant-picker-cell {
    padding: 0;
}

.ant-picker-cell .ant-picker-cell-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #411653;
}

/*.ant-picker-footer {*/
/*    display: none;*/
/*}*/

.ant-picker-ok {
    color: #FFFFFF;
    background: #411653;
    border-color: #411653;
}

.ant-btn-primary {
    color: #FFFFFF;
    background: #411653;
    border-color: #411653;
}

.ant-btn[disabled] {
    background: #411653;
    border-color: #411653;
}

.ant-btn[disabled]:hover {
    background: #411653;
    border-color: #411653;
}

.ant-btn:hover, .ant-btn:focus {
    background: #411653;
    border-color: #411653;
}

.ant-picker-cell-today {
    background: #411653;
    color: #FFFFFF;
}

.ant-picker-now {
    display: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
}

.ant-picker-header > button {
    width: auto;
    min-width: 0;
    color: #353535;
}

.ant-picker-header-prev-btn {
    margin-right: 10px;
}

.ant-collapse-header {
    padding: 0
}

.ant-collapse {
    border: none;
    background-color: #FFFFFF;
}

.ant-collapse-content-box {
    padding: 0;
}

.ant-collapse-content {
    margin-top: 10px;
    /*background: #F5F5F5;*/
    border-top: none;
}

.ant-collapse-content-box p {
    padding: 10px 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding: 0;
    left: auto;
    right: 0;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: none;
    border: none;
}

.ant-pagination-item-active a {
    color: #411653;
}

.ant-pagination-item:focus-visible a, .ant-pagination-item:hover a {
    color: #411653;
}

.ant-picker:hover, .ant-picker-focused {
    border-color: #411653;
}

.ant-picker-focused {
    border-color: #411653;;
    box-shadow: none;
}

.ant-picker:hover, .ant-picker-focused {
    border-color: #411653;
}

.ant-picker-datetime-panel {
    display: flex;
}

.ant-picker-cell-today {
    background-color: #FFFFFF;
    color: #411653;
}

.ant-picker-cell::before {
    height: 30px;
}
:focus-visible {
    outline: none;
}

.ant-picker-time-panel-column:hover::-webkit-scrollbar { width: 0 !important; }

.ant-picker-time-panel-column:hover  { -ms-overflow-style: none !important; }

.ant-picker-time-panel-column:hover  { overflow: -moz-scrollbars-none !important; }

@media (max-width: 1500px) and (min-width: 1110px) {
    .ant-picker{
        /*padding-left: 6px;*/
        /*padding-right: 4px;*/
    }
    .ant-picker-input{
        font-size: 13px !important;
    }
}

@media (max-width: 1110px) {
    .ant-picker-datetime-panel {
        display: block;
    }

    .scrolling {
        display: flex;
        flex-direction: column;
        flex: auto;
        overflow-y: auto;
        position: relative;
    }

    .ant-picker-panel{
        height: 465px;
        z-index: 9999 !important;
    }

    .ant-picker-time-panel {
        height: 102px;
        padding-left: 15px;
        width: 275px;
        overflow: hidden;
    }

    .ant-picker-time-panel-cell-inner{
        padding-left: 10px;
    }

    .ant-picker-time-panel .ant-picker-content {
        display: flex;
        flex-direction: column;
        height: 102px;
        /*flex: auto;*/
    }
    .ant-picker-time-panel-column{
        /*flex: 1 0 auto;*/
        width: 100%;
        height: 30px;
        margin: 0;
        padding: 0;
        display: flex;
        text-align: left;
        list-style: none;
        transition: background 0.3s;


    }
    .ant-picker-time-panel-column::-webkit-scrollbar { height: 0; }
    .ant-picker-time-panel-column{ -ms-overflow-style: none; }

    /*.ant-picker-time-panel-column:first-child{*/
    /*    padding-left: 0;*/
    /*    border-left: 0;*/
    /*}*/
    .ant-picker-time-panel-column:first-child>.ant-picker-time-panel-cell:first-child{
        padding-left: 0;
    }
    .ant-picker-time-panel-column:not(:first-child) {
        padding-left: 0;
        border-left: 0;
    }
    .ant-picker-header-view{
        padding-left: 10px;
    }
    .ant-picker-content{
        margin-top:6px;
        width: 259px;
        height: 86px;
        /*row-gap: 4px;*/
    }
    .ant-picker-time-panel-column:first-child{
        margin-bottom: 3px;
    }
    .ant-picker-date-panel{
        padding-bottom: 13px;
    }
    .ant-picker-time-panel-cell, .ant-picker-time-panel-cell-disabled{
        height: 30px;
        /*padding-bottom: 12px;*/
    }
    .ant-picker-time-panel-cell-inner{
        width: 33px;
        display: flex;
        justify-content: center;
        padding-left: 0;
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner{
        /*height: 34px;*/
        height: 30px;
        display: flex;
        justify-content: center;
        padding-left: 0;
        width: 33px;
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell:last-child{
        margin-right: 30px;
    }
    .ant-picker-time-panel-cell-selected{
        height: 30px;
    }
    .ant-picker-time-panel{
        margin-right: 10px;
    }
    .ant-picker-time-panel-column:hover{
        overflow-y: hidden;
    }
    .ant-picker-time-panel-column:nth-child(2){
        margin-bottom: 3px;
    }

}

.ant-picker-footer{
    display: none;
}
.ant-picker-panel-container{
    z-index: 999999 !important;
}

.ant-picker-dropdown{
    z-index: 99999 !important;
}
