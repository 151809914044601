::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    overflow: scroll;
    overflow-x: hidden;
}
.tableContainer{
    // padding: 10px;
    padding: 0 25px;
    .table{
        width: 100%;
    }
    .filters{
        display: flex;
        margin-top: 25px;
        gap: 15px;
        .filterBlock{ 
            &__search{
                width: 250px;
                height: 32px;
            }
        }
    }
    
    
}

.filterBlock__datePicker{
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
        margin-right: 10px;
    }
}
.deviceStatisticMain{
    min-width: 80vw;
    min-height: 80vh;
    .deviceStatisticHeader{
        color: black;
        font-size: 22px;
    }
    
}
.deviceStatisticTable{
    min-width: 80vw;
    
}

.deviceStatisticMainJobsTableHeader{
    color: black;
    font-size: 22px;
    min-width: 800px;
    
}

.showMore{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.filters{
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
.editVendingModal{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    z-index: 0;
}

.deviceCommandsModal{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.verMain{
    // width: 80vw;
    // height: 80vh
}
.deviceAdHeader{
    width: 100%;
    display: flex;
    color: black;
    font-size: 22px; 
}
.verFilters{
    margin-top: 20px;
    width: 250px;
}

.rowClassName{
    
}

.badRowClassName{
    background-color: rgba(231, 147, 147, 0.459);
}

.badRowClassName:hover{
    background-color: rgba(231, 147, 147, 0.664);
}
.CustomDropDown{
    z-index: 9999999;
}

