.headerBlock {
    height: 138px;
    display: flex;
    width: 1440px;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    size: 16px;
    font-style: normal;
    line-height: 15px;
    padding-left: 40px;
    padding-right: 40px;
}

.navButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 36px;
    cursor: pointer;
}

.qrIcon{
    stroke:#BCA263;
    fill: #BCA263;
}

.navButtons div {
    margin-right: 24px;
}

.navButtons div:last-child {
    margin-right: 0;
}

.navButton {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
}

.navButton:hover {
    padding-top: 4px;
    color: #BCA263;
}

.navButton:hover::after {
    content: "";
    margin: auto;
    display: block;
    width: 20px;
    padding-top: 3px;
    border-bottom: 1px solid #BCA263;
}

.navButtons p {
    display: block;
    text-align: center;
}

.search {
    display: none;
}

.mobileRightBlock {
    display: none;
}


.funcButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orderPhone {
    display: flex;
    margin-bottom: 13px;
    cursor: pointer;
}

.phone {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.leftBlock {
    display: flex;
    flex-direction: row;
    flex-grow: 0.5;
}

.rightBlock {
    display: flex;
    flex-direction: row;
    flex-grow: 0.5;
    justify-content: space-between;
}

.logoBlock {
    flex-grow: 1;
}

.logo {
    cursor: pointer;
    display: block;
}

.buttons {
    cursor: pointer;
}

.userBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.userName {
    position: absolute;
    top: 100%;
    right: -50%;
    font-size: 14px;
    line-height: 17px;

    color: #BCA263;
    cursor: pointer;
}


.gold{
    font-size: 14px;
    line-height: 17px;
    color: #BCA263;
    cursor: pointer;
}


.logout {
    text-decoration-line: underline;
    color: #BCA263;
    cursor: pointer;
}

.mobileLogo {
    display: none;
}

.bagBlock {
    position: relative;
}

.numBlock {
    position: absolute;
    top: -50%;
    right: -30%;
    height: 16px;
    width: 16px;
    background: #5B031E;
    border-radius: 20px;
    display: flex;
    justify-content: center;

    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    color: #FFFFFF;
}
.numBlockLeft {
    top: -60%;
}
.menuList {
    width: 329px;
    height: 443px;
    padding: 26px;
}

.menuItem {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #353535;
    padding-top: 12px;
    padding-right: 16px;
}

.menuButtonBig {
    display: block;
}

.menuButtonMobile {
    display: none;
}

.menuButtonBlock {
    display: block;
}

.menuButtonBlockMobile {
    display: none;
}

.menuSubItem {
    display: flex;
    width: 110%;
    align-items: center;
    background: #F5F5F5;
    height: 40px;
}

.messageInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.messageInfo span {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.messageInfo span:first-child {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.messageInfo span:last-child {
    margin-bottom: 0;
    margin-top: 22px;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #898989;
}

@media screen and (max-width: 1440px) {
    .headerBlock {
        padding-top: 20px;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
}

@media screen and (max-width: 1100px) {
    .navButtons {
        display: none;
    }

    .funcButtons {
        display: none;
    }

    .orderBlock {
        display: none;
    }

    .search {
        display: block;
        padding-left: 25px;
    }

    .mobileRightBlock {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .logoBlock {
        display: flex;
        justify-content: center;
    }

    .headerBlock {
        padding-top: 20px;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .phoneMobile {
        padding-right: 25px;
        position: relative;
    }

    .numBlock {
        left: 25%;
        right: auto;
    }
}

@media screen and (max-width: 1110px) {
    .logoBlock {
        width: 126px;
        height: 72px;
    }

    .messageInfo span:first-child {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
    }
}

@media screen and (max-width: 800px) {

    .headerBlock {
        height: 112px;
    }

    .logo {
        display: none;
    }

    .mobileLogo {
        display: block;
    }

    .menuButtonBig {
        display: none;
    }

    .menuButtonMobile {
        display: block;
    }

    .menuButtonBlock {
        display: none;
    }

    .menuButtonBlockMobile {
        display: block;
    }
}