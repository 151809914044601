::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    overflow: scroll;
    overflow-x: hidden;
}
.tableContainer{
    padding: 10px;
    padding: 0 25px;
    width: 100%;
    .table{
        width: 100%;
    }
    .filters{
        display: flex;
        margin-top: 25px;
        gap: 15px;
        .filterBlock{ 
            &__search{
                width: 250px;
                height: 32px;
            }
        }
       
    }
    
    
}

.filterBlock__datePicker{
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
        margin-right: 10px;
    }
}
.adMain{
    min-width: 80vw;
    min-height: 80vh;
    .adHeader{
        color: black;
        font-size: 22px;
    }
    .AddHeader{
        color: black;
        font-size: 22px;
    }
    
}
.adTable{
    min-width: 80vw;
    
}

.deviceStatisticMainJobsTableHeader{
    color: black;
    font-size: 22px;
    min-width: 800px;
    
}

.showMore{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.filters{
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
.adModal{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    z-index: 0;
    .table{
        width: 100vh;
       
    }
    .badRowClassName{
        background-color: rgba(231, 147, 147, 0.459);
    }

    // .selector{
    //     margin-top: 10px;
    //     z-index: 10000;
    // }
}

.adModalMonitor{
    min-height: 100px;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    z-index: 0;
    .table{
        display: inline-block;
        width: 100vh;
        .tableWithCheck{
            margin: 0 0 0 0;
            width: 100vh;
            float: right;
        }
        // .checkBoxForTable {
        //     position: absolute;
        //     top:20%;
        //     width: 10px;
        //     // margin: 0 0 0 0;
        //     // .checkb{
        //     //     justify-content: space-between;
        //     // }
            
        // } 
    }
    
    .badRowClassName{
        background-color: rgba(231, 147, 147, 0.459);
    }
}
.AddHeader{
    color: black;
    font-size: 22px;
}

.deviceCommandsModal{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.deviceAdMain{
    width: 80vw;
    height: 80vh
}
.deviceAdHeader{
    width: 100%;
    display: flex;
    color: black;
    font-size: 22px; 
}
.deviceFilters{
    margin-top: 20px;
    width: 250px;
}

.rowClassName{
    
}


.badRowClassName{
    background-color: rgba(231, 147, 147, 0.459);
}

.badRowClassName:hover{
    background-color: rgba(231, 147, 147, 0.664);
}
.addBlock {
    width: 256px;
    height: 455px;
    background: #FFFFFF;
    border: 1px dashed #662482;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
      
}
.Adss{
    display: inline-block;
    width: 100%;
    padding-top: 10px;

    
    .Arrr{
      margin-top: 10px;
            
    .buttomsArrowsr{
        cursor: pointer;
        box-sizing: border-box;
        border: 0.5px solid #662482;
        border-radius: 36px;
        width: 30px;
        height: 30px;
        transition: 0.3s ease;
        font-weight: 600;
        font-size: 12px;
        margin-left: 10%;

        &:hover {
            // cursor: pointer;
            background-color: #662482;
            color: white;
        }
    }
    .buttomsArrowsrdis{
        cursor: pointer;
        box-sizing: border-box;
        border: 0.5px solid rgb(2, 2, 2);
        border-radius: 36px;
        width: 30px;
        height: 30px;
        transition: 0.3s ease;
        font-weight: 600;
        font-size: 12px;
        margin-left: 10%;
        opacity: 0.33;

        &:hover {
            // cursor: pointer;
            background-color: #c1c0c1;
            color: rgb(2, 2, 2);
            border: 0.5px solid rgb(62, 59, 59);
        }
        
    }
    .buttomsArrowsldis{
        cursor: pointer;
        box-sizing: border-box;
        border: 0.5px solid rgb(2, 2, 2);
        border-radius: 36px;
        width: 30px;
        height: 30px;
        transition: 0.3s ease;
        font-weight: 600;
        font-size: 12px;
        margin-left: 6%;
        opacity: 0.33;

        &:hover {
            // cursor: pointer;
            background-color: #c1c0c1;
            color: rgb(2, 2, 2);
            border: 0.5px solid rgb(62, 59, 59);
        }
    }
   
    .buttomsArrowsl{
        cursor: pointer;
        box-sizing: border-box;
        border: 0.5px solid #662482;
        border-radius: 36px;
        width: 30px;
        height: 30px;
        transition: 0.3s ease;
        font-weight: 600;
        font-size: 12px;
        margin-left: 6%;

        &:hover {
            // cursor: pointer;
            background-color: #662482;
            color: white;
        }
    }
    @media screen 
and (min-device-width: 1200px) 
and (max-device-width: 1600px) 
and (-webkit-min-device-pixel-ratio: 1) {
    .buttomsArrowsl{
        cursor: pointer;
        box-sizing: border-box;
        border: 0.5px solid #662482;
        border-radius: 36px;
        width: 30px;
        height: 30px;
        transition: 0.3s ease;
        font-weight: 600;
        font-size: 12px;
        margin-left: 7.9%;

        &:hover {
            // cursor: pointer;
            background-color: #662482;
            color: white;
        }
    }
    .buttomsArrowsldis{
        cursor: pointer;
        box-sizing: border-box;
        border: 0.5px solid rgb(2, 2, 2);
        border-radius: 36px;
        width: 30px;
        height: 30px;
        transition: 0.3s ease;
        font-weight: 600;
        font-size: 12px;
        margin-left: 7.9%;
        opacity: 0.33;

        &:hover {
            // cursor: pointer;
            background-color: #c1c0c1;
            color: rgb(2, 2, 2);
            border: 0.5px solid rgb(62, 59, 59);;
        }
    }
}
    
}
}
.LeftBlock{
    padding: 10px 10px 10px 10px;
    // border:1px solid;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    float: right;
    // margin-left:30%;
    margin-top: 0px;
    // align-items: center;
    // align-content: center;
    
    .orderText{
        font-weight: 500;
        font-size: 16px;
        display: flex;
        justify-content: center;
        // text-align: center;
        // padding-left: 50px;
        text-transform: uppercase;
        color: #000000;
        padding-bottom: 14px;
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 20px;
        
    }
    // display: flex;
    // margin-bottom: 800%;
    // margin-right: 20%;
    // bottom:200px;
    // margin-bottom: 80%;
.orderList{
    
    width: 300px;
    height: 50px;
    cursor: grab;
    display: flex;
    flex-direction: column;
    // border: 1px solid #662482;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top: 15px;
    margin-left: 14px;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    padding: 5px 5px 5px 5px;
    .orderNumber{
        
    }
    // -webkit-transition: .5s all;   
    // -webkit-transition-delay: 5s; 
    // -moz-transition: .5s all;   
    // -moz-transition-delay: 5s; 
    // -ms-transition: .5s all;   
    // -ms-transition-delay: 5s; 
    // -o-transition: .5s all;   
    // -o-transition-delay: 5s; 
    transition: .5s all;   
    transition-delay: 0.1s; 
    &:hover {
        // cursor: pointer;
        background-color: #876c99;
        // #662482;
        color: white;
        // -webkit-transition-delay: 0s;
        // -moz-transition-delay: 0s;
        // -ms-transition-delay: 0s;
        // -o-transition-delay: 0s;
        transition-delay: 0s;
    }
    

}
}
.forSureDelete{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    justify-content: center;
    .forSureTitle{
        font-size: 22px;
    }
}

.photoDiv{
    width: 256px;
    height: 455px;
}

.photoimg{
    width: 256px;
    height: 455px;
    
}


// .tabsHeader {
//     // display: flex;
//     // justify-content: space-between;
//     // padding-bottom: 10px;
//     cursor: pointer;
//     display: inline-block;
//     // padding-right:10px;
//     // padding-left:10px;
//      border:1px solid;
//      background-color:#662482;
//      color:#FFFFFF;
//      transition: 0.3s ease;
//   }

// .tabsHeader:hover {
//     color: #411653;
// }

  .tabItem {
    text-align: center;
    cursor: pointer;
    width: 128px;
    display: inline-block;
    padding-right:10px;
    padding-left:10px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    background-color:  #FFFFFF;
    color: #662482;
    // border-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px ;
    transition: .5s all;   
    transition-delay: 0.1s; 
    margin-bottom: 5px;

        &:hover{
            background-color: #876c99;
            color: white;
            transition-delay: 0s;
        }
        &.active{
            background-color: #876c99;
            color: white;
        }
       

  }
  .tabItemR{
    text-align: center;
    cursor: pointer;
    width: 128px;
    display: inline-block;
    padding-right:10px;
    padding-left:10px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    background-color:  #FFFFFF;
    color: #662482;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px ;
    transition: .5s all;   
    transition-delay: 0.1s; 
    margin-bottom: 5px;
    
        &:hover{
            background-color: #876c99;
            color: white;
            transition-delay: 0s;
        }
        &.active1{
            background-color: #876c99;
            color: white;
        }
  }

  .photoslide{
    margin-top: 20px;
    
  }
  .CustomDropDown{
    z-index: 9999999;
  }
 .blockWImg{
    display: inline-block;
    .textImg{
        opacity: 0.5;
        position: absolute;
        background: #222;
        color: #fff;
        border-radius: 2px;
        font-size: 15px;
        padding: 3px 5px;
    }
 }
 .textVid{
    opacity: 0.5;
        position: absolute;
        background: #222;
        color: #fff;
        border-radius: 2px;
        font-size: 15px;
        padding: 3px 5px;
        z-index: 99999999999;
 }
 .checkBoxForTable{
    // padding: 10px 0 0 0;
    // gap: 25px;
    float: left;
    // margin-top: 55px;
    margin-top: 5.7vh;
    // border-left: 1px solid ;
    position:absolute;
    left:410px;
    .checkb{
        display: flex;
        flex-direction: row-reverse;
        margin-top: 3.1vh;
        // margin-top: 25px;
        // color:#662482;
    }
 }
 @media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .checkBoxForTable{
        // padding: 10px 0 0 0;
        // gap: 25px;
        float: left;
         margin-top: 55px;
         left:320px;
        // margin-top: 7%;
        // border-left: 1px solid ;
        
        .checkb{
            display: flex;
            flex-direction: row-reverse;
            // margin-top: 3vh;
            margin-top: 25px;
            // color:#662482;
        }
     }
}
.loaderContainer {
    
    display: flex;
    justify-content: center;
    align-items: center;
    // position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 999999;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

  .imageVisible {
    
  }
  .smoothImageWrapper {
    display: flex;
    // flex-direction: column;
    // border: 3px solid ;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    width: 256px;
    height: 455px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
    .imageHidden{
        background-image: none;
        display: none;
        width: 0px;
        height: 0px;
        border: 5px dotted #876c99;
        border-radius: 50%;
        // color: #000000;
        margin: 0 0 0 0;
        padding: 20px 20px 20px 20px;
        // display: inline-block;
        // // position: relative;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        animation: rotation 2s linear infinite;
        @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          } 
    }
// .smoothPreloader{
//     width: 420px;
//     height: 230px;
//     align-items: center;

//     .loader {
//         width: 42px;
//         height: 42px;
//         border: 5px dotted black;
//         border-radius: 50%;
//         display: inline-block;
//         position: relative;
//         box-sizing: border-box;
//         animation: rotation 2s linear infinite;
//         @keyframes rotation {
//             0% {
//               transform: rotate(0deg);
//             }
//             100% {
//               transform: rotate(360deg);
//             }
//           } 
//       }
// }




.buttonClosePrevie{
    align-items:end;
    position: absolute;
    z-index: 9999999;
    cursor: pointer;
    box-sizing: border-box;
    // border: 0.5px solid #662482;
    border-radius: 36px;
    color: #662482;
    background-color: white;
        width: 30px;
        height: 30px;
        transition: 0.3s ease;
        font-weight: 600;
        opacity: 0.7;
        // position: absolute;
        &:hover {
            // cursor: pointer;
            opacity: 1;
            background-color: #662482;
            color: white;
        }
}
