.headerMain{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    background-color: #DDDB00;
    box-shadow: 0 0 6px rgb(0 0 0 / 10%);
    z-index: 999;
    min-width: 1540px;
    .exit{
        position: absolute;
        display: flex;
        align-items: center;
        gap: 10px;
        right: 60px;
        top: 20%;
        color: #fff;
        cursor: pointer;
    }
}