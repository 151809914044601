.text {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #353535;
  word-wrap: break-word;
  text-align: center;
}
.inviteQRCode{
  width: 77px;
  height: 77px;
  margin: 4px 0 7px 0;
  /*background-color: black;*/
}

.wrap {
  //min-height: 160px;
  min-width: 370px;
  border-radius: 8px ;
  padding: 32px 40px ;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #FFFFFF;
  justify-content: center;
  align-items: center;
  // max-width: 1000px;
}


.loader_block{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.textContainer{
  color: white;
  font-size: 25px;
  font-weight: 300;
}

.loader_container {
  // background: #FFFFFF;
  // border-radius: 100%;
  overflow: hidden;
  
  display: flex;
  background-color: #585858d2;
  border-radius: 100%;
  gap: 15px;
  font-size: 25px;
  font-weight: 300;
  color: rgb(235, 235, 235);
  white-space: nowrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__mini {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    height: 120px;
    & > * {
      border-radius: 100%;
    }
    
    z-index: 1400;
    bottom: 50px;
    left: 50px;
  }
}

.close_icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.buttons {
  display: flex;
  justify-content: center;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
  & > * {
    flex-grow: 1;
  }
  margin-top: 30px;
}

.buttonInfoModal{
  margin-top: 30px;
}

.mapInfo{
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #353535;
}

.mapModal{
  min-width: 0px;
  max-width: 2000px;
  height: clamp(550px, 820px, calc(100vh - 50px));
  padding: 24px 42px;
}

@media (max-width: 1110px) {
  .wrap {
    min-width: 100%;
    padding: 32px 20px ;
  }
}
