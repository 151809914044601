
.tableContainer{
    // padding: 10px;
    padding: 0 25px;
    .table{
        width: 100%;
    }
    .filters{
        display: flex;
        margin-top: 25px;
        gap: 15px;
        .filterBlock{ 
            &__search{
                width: 250px;
                height: 32px;
            }
        }
    }
    
    
}
// .Selector{
//     z-index: 999999;
// }

.filterBlock__datePicker{
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
        margin-right: 10px;
    }
}
.deviceStatisticMain{
    min-width: 80vw;
    min-height: 80vh;
    .deviceStatisticHeader{
        color: black;
        font-size: 22px;
    }
    
}
.deviceStatisticTable{
    min-width: 80vw;
    
}

.deviceStatisticMainJobsTableHeader{
    color: black;
    font-size: 22px;
    min-width: 800px;
    
}

.showMore{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.filters{
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.editUsersModal{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    z-index: 0;

    .password{
        display: flex;
        flex-direction: row;
        // justify-content: space-between;

    }
    .eye{
        padding-left: 5px;
        cursor: pointer;
    }
    
}
        .createUsersModal{
            min-height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;
            z-index: 0;
            width:350px;
            
        
        .mainUser{
            // width: 70%;
            display: flex;
            flex-direction: column;
            gap:30px;
            border-top: 1px solid rgb(184, 184, 184);
            border-bottom: 1px solid rgb(184, 184, 184);
            padding-bottom: 40px;
            padding-top: 10px;
            // margin: 0 auto;
            // align-items: center;
            .login{
                padding-top: 20px;
                width: 110%;
                display: flex;
                flex-direction: row;
                align-items: center;
                
            }
            .emailForUser{
                width: 55%;
                display: flex;
                flex-direction: row;
            }
            .accauntObj{
                display: flex;
                flex-direction: column;
                margin-right: 30px;
            }
            .otherInfo{
                display: flex;
                flex-direction: column;
                gap:30px;
                padding-bottom: 15px;
                padding-top: 10px;
                .PhoneTown{
                    width: 110%;
                    display: flex;
                    flex-direction: row;
                }
                .NameOrg{
                    display: flex;
                    align-items: center;
                    width: 55%;
                }
             
                
           }
              .MultipleSelector{
                    display: flex;
                    align-items: center;
                    // width: 100%;
                    margin: 0 auto;
                }
            }
           
        }

        


.deviceAdMain{
    width: 80vw;
    height: 80vh
}
.deviceAdHeader{
    width: 100%;
    display: flex;
    color: black;
    font-size: 22px; 
}
.deviceFilters{
    margin-top: 20px;
    width: 250px;
}

.rowClassName{
    
}

.badRowClassName{
    background-color: rgba(231, 147, 147, 0.459);
}

.badRowClassName:hover{
    background-color: rgba(231, 147, 147, 0.664);
}
.CustomDropDown {
    z-index: 999999; /* Define the desired z-index value */
  }

