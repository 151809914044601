.authMain{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.479);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 1300;
    

    .authModal{
        width: 400px;
        height: 400px;
        border-radius: 8px ;
        background-color: white;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        &__title{
            font-size: 22px;
            display: flex;
            justify-content: center;
            text-align: center;
        }

        &__inputs{

            &__input{

                &__title{
                    
                }

                &__textInput{
                    height: 40px;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        &__buttons{
            // align-self: flex-end;
            // justify-self: flex-end;
            display: flex;
            justify-content: center;
            &__accept{
                background-color: #DDDB00;
            }
        }
    }
}